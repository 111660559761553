import {
  componentThemesDefault,
  componentThemesAlt,
  woodwardTheme,
} from './shared-theme-data';

export const wwDefaultTheme = {
  ...woodwardTheme,
  ...componentThemesDefault,
};

export const wwAltTheme = {
  ...woodwardTheme,
  ...componentThemesAlt,
};
